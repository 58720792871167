@tailwind base;
@tailwind components;
@tailwind utilities;

.date-input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.date-input {
  padding: 10px;
  font-size: 16px;
  cursor: pointer; /* Makes the input look clickable */
}
