.element {
  background: #009d4d;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer {
  background: #ffffff;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
